import React from 'react';
import styled from 'styled-components';

import { FoldingCube } from 'styled-spinkit';

interface LoadingPageProps {
  color?: string;
  fullscreen?: boolean;
}

export default function LoadingPage(props: LoadingPageProps) {
  return (
    <Page color={props.color} fullscreen={props.fullscreen}>
      <FoldingCube color='#ffecd1' size={80} />
    </Page>
  );
}

const Page = styled.div<any>`
  height: ${props => props.fullscreen ? '100vh' : '100%'};
  width: ${props => props.fullscreen ? '100vw' : '100%'};
  background: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
`;
