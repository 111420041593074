import { ApolloProvider } from '@apollo/react-hooks';
import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';

import LoadingPage from 'Components/pages/LoadingPage';
import getClient from './apollo';
import { useStoreActions } from './store';

interface AuthAppState {
  status: AuthStatus;
  user?: firebase.User;
}

export interface AuthState extends AuthAppState {
  token?: string;
}

export enum AuthStatus {
  Loading,
  Connected,
  Disconnected
}

interface Props {
  children: React.ReactNode;
}

export default function Auth({ children }: Props) {
  const [appState, setAppState] = useState<AuthAppState>({ status: AuthStatus.Loading });
  const [tokenIatWaiting, setTokenIatWaiting] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const setUserData = useStoreActions(actions => actions.user.setUserData);

  useEffect(() => {
    if (tokenIatWaiting) {
      setTimeout(() => {
        setTokenIatWaiting(false);
      }, 2000);
    }
  }, [tokenIatWaiting]);

  useEffect(() => firebase.auth().onAuthStateChanged(async user => {
    if (user) {
      const userToken = await user.getIdToken(true);

      setUserData({ firebaseId: user.uid, tokenId: userToken });

      setTokenIatWaiting(true);

      setToken(userToken);

      setAppState({
        status: AuthStatus.Connected,
        user
      });
    } else {
      setAppState({
        status: AuthStatus.Disconnected
      });
    }
  }), []);

  if ((appState.status === AuthStatus.Loading) || tokenIatWaiting) {
    return <LoadingPage fullscreen />;
  }

  const apolloClient = getClient(token);

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
}
