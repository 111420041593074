/* tslint:disable */
import * as Types from "./types"
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export const DocumentFragmentFragmentDoc = gql`
    fragment DocumentFragment on documents {
  id
  name
  url
  metadata
  createdAt
  project {
    id
    name
  }
  employee {
    id
    name
  }
}
    `;
export const DailyLogsByIdActivitiesDocument = gql`
    query DailyLogsByIdActivities($dailyLogId: uuid!) {
  dailyLog: dailyLogs_by_pk(id: $dailyLogId) {
    id
    status
    dailyLogProjects {
      project {
        id
        name
        code
        color
        jobs(where: {dailyLog: {id: {_eq: $dailyLogId}}}) {
          id
          quantity
          expectedQuantity
          activity {
            id
            name
          }
          product {
            id
            name
            sector {
              id
              name
            }
            productUnit {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

    export function useDailyLogsByIdActivitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogsByIdActivitiesQuery, Types.DailyLogsByIdActivitiesQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogsByIdActivitiesQuery, Types.DailyLogsByIdActivitiesQueryVariables>(DailyLogsByIdActivitiesDocument, baseOptions);
    };
      export function useDailyLogsByIdActivitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogsByIdActivitiesQuery, Types.DailyLogsByIdActivitiesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogsByIdActivitiesQuery, Types.DailyLogsByIdActivitiesQueryVariables>(DailyLogsByIdActivitiesDocument, baseOptions);
      };
      
export type DailyLogsByIdActivitiesQueryHookResult = ReturnType<typeof useDailyLogsByIdActivitiesQuery>;
export type DailyLogsByIdActivitiesQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogsByIdActivitiesQuery, Types.DailyLogsByIdActivitiesQueryVariables>;
export const DailyLogsByIdProjectListDocument = gql`
    query DailyLogsByIdProjectList($dailyLogId: uuid!) {
  dailyLog: dailyLogs_by_pk(id: $dailyLogId) {
    id
    status
    dailyLogProjects {
      project {
        id
        name
      }
    }
  }
}
    `;

    export function useDailyLogsByIdProjectListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogsByIdProjectListQuery, Types.DailyLogsByIdProjectListQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogsByIdProjectListQuery, Types.DailyLogsByIdProjectListQueryVariables>(DailyLogsByIdProjectListDocument, baseOptions);
    };
      export function useDailyLogsByIdProjectListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogsByIdProjectListQuery, Types.DailyLogsByIdProjectListQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogsByIdProjectListQuery, Types.DailyLogsByIdProjectListQueryVariables>(DailyLogsByIdProjectListDocument, baseOptions);
      };
      
export type DailyLogsByIdProjectListQueryHookResult = ReturnType<typeof useDailyLogsByIdProjectListQuery>;
export type DailyLogsByIdProjectListQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogsByIdProjectListQuery, Types.DailyLogsByIdProjectListQueryVariables>;
export const ProjectsListDocument = gql`
    query ProjectsList {
  projects {
    id
    deletedAt
    color
    projectCode: code
    projectName: name
    location
    projectActivities {
      product_Activity {
        activity {
          id
          name
          deletedAt
        }
      }
    }
    manager {
      id
      label: name
      value: id
    }
    organization {
      id
      label: name
      value: id
    }
  }
}
    `;

    export function useProjectsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.ProjectsListQuery, Types.ProjectsListQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.ProjectsListQuery, Types.ProjectsListQueryVariables>(ProjectsListDocument, baseOptions);
    };
      export function useProjectsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.ProjectsListQuery, Types.ProjectsListQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.ProjectsListQuery, Types.ProjectsListQueryVariables>(ProjectsListDocument, baseOptions);
      };
      
export type ProjectsListQueryHookResult = ReturnType<typeof useProjectsListQuery>;
export type ProjectsListQueryResult = ApolloReactCommon.QueryResult<Types.ProjectsListQuery, Types.ProjectsListQueryVariables>;
export const DailyLogInfosDocument = gql`
    query DailyLogInfos($dailyLogId: uuid!) {
  dailyLog: dailyLogs_by_pk(id: $dailyLogId) {
    id
    title
    description
  }
}
    `;

    export function useDailyLogInfosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogInfosQuery, Types.DailyLogInfosQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogInfosQuery, Types.DailyLogInfosQueryVariables>(DailyLogInfosDocument, baseOptions);
    };
      export function useDailyLogInfosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogInfosQuery, Types.DailyLogInfosQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogInfosQuery, Types.DailyLogInfosQueryVariables>(DailyLogInfosDocument, baseOptions);
      };
      
export type DailyLogInfosQueryHookResult = ReturnType<typeof useDailyLogInfosQuery>;
export type DailyLogInfosQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogInfosQuery, Types.DailyLogInfosQueryVariables>;
export const DailyLogStatusDocument = gql`
    query DailyLogStatus($dailyLogId: uuid!) {
  dailyLog: dailyLogs_by_pk(id: $dailyLogId) {
    id
    status
  }
}
    `;

    export function useDailyLogStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogStatusQuery, Types.DailyLogStatusQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogStatusQuery, Types.DailyLogStatusQueryVariables>(DailyLogStatusDocument, baseOptions);
    };
      export function useDailyLogStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogStatusQuery, Types.DailyLogStatusQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogStatusQuery, Types.DailyLogStatusQueryVariables>(DailyLogStatusDocument, baseOptions);
      };
      
export type DailyLogStatusQueryHookResult = ReturnType<typeof useDailyLogStatusQuery>;
export type DailyLogStatusQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogStatusQuery, Types.DailyLogStatusQueryVariables>;
export const DailyLogTeamInfosDocument = gql`
    query DailyLogTeamInfos($dailyLogId: uuid!) {
  dailyLog: dailyLogs_by_pk(id: $dailyLogId) {
    id
    date
    team {
      id
      color
      name
      teamLeader {
        id
        name
        assets
      }
    }
  }
}
    `;

    export function useDailyLogTeamInfosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogTeamInfosQuery, Types.DailyLogTeamInfosQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogTeamInfosQuery, Types.DailyLogTeamInfosQueryVariables>(DailyLogTeamInfosDocument, baseOptions);
    };
      export function useDailyLogTeamInfosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogTeamInfosQuery, Types.DailyLogTeamInfosQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogTeamInfosQuery, Types.DailyLogTeamInfosQueryVariables>(DailyLogTeamInfosDocument, baseOptions);
      };
      
export type DailyLogTeamInfosQueryHookResult = ReturnType<typeof useDailyLogTeamInfosQuery>;
export type DailyLogTeamInfosQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogTeamInfosQuery, Types.DailyLogTeamInfosQueryVariables>;
export const DailyLogCommentsDocument = gql`
    query DailyLogComments($dailyLogId: uuid!) {
  dailyLog: dailyLogs_by_pk(id: $dailyLogId) {
    id
    comments {
      id
      date
      editDate
      message
      employee {
        id
        name
        icon
        assets
      }
      parentCommentId
    }
  }
}
    `;

    export function useDailyLogCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogCommentsQuery, Types.DailyLogCommentsQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogCommentsQuery, Types.DailyLogCommentsQueryVariables>(DailyLogCommentsDocument, baseOptions);
    };
      export function useDailyLogCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogCommentsQuery, Types.DailyLogCommentsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogCommentsQuery, Types.DailyLogCommentsQueryVariables>(DailyLogCommentsDocument, baseOptions);
      };
      
export type DailyLogCommentsQueryHookResult = ReturnType<typeof useDailyLogCommentsQuery>;
export type DailyLogCommentsQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogCommentsQuery, Types.DailyLogCommentsQueryVariables>;
export const DailyLogDocumentsDocument = gql`
    query DailyLogDocuments($dailyLogId: uuid!) {
  dailyLog: dailyLogs_by_pk(id: $dailyLogId) {
    id
    dailyLogProjects {
      project {
        id
        name
        code
        documents(where: {dailyLogId: {_eq: $dailyLogId}}) {
          ...DocumentFragment
        }
        sharedDocument: documents(where: {dailyLogId: {_is_null: true}, showInDailyLogs: {_eq: true}}) {
          ...DocumentFragment
        }
      }
    }
    company {
      id
      organizations(where: {self: {_eq: true}}) {
        id
        name
      }
      documents(where: {projectId: {_is_null: true}, dailyLogId: {_is_null: true}, showInDailyLogs: {_eq: true}}) {
        ...DocumentFragment
      }
    }
  }
}
    ${DocumentFragmentFragmentDoc}`;

    export function useDailyLogDocumentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogDocumentsQuery, Types.DailyLogDocumentsQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogDocumentsQuery, Types.DailyLogDocumentsQueryVariables>(DailyLogDocumentsDocument, baseOptions);
    };
      export function useDailyLogDocumentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogDocumentsQuery, Types.DailyLogDocumentsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogDocumentsQuery, Types.DailyLogDocumentsQueryVariables>(DailyLogDocumentsDocument, baseOptions);
      };
      
export type DailyLogDocumentsQueryHookResult = ReturnType<typeof useDailyLogDocumentsQuery>;
export type DailyLogDocumentsQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogDocumentsQuery, Types.DailyLogDocumentsQueryVariables>;
export const DailyLogEmployeeListDocument = gql`
    query DailyLogEmployeeList($dailyLogId: uuid!) {
  dailyLog: dailyLogs_by_pk(id: $dailyLogId) {
    id
    status
    placeholders: dailyLogPlaceholders(where: {roleId: {_is_null: false}}, order_by: {organization: {name: asc}, role: {name: asc}}) {
      id
      description
      role {
        id
        name
      }
      organization {
        id
        name
      }
    }
    dailyLogEmployees(order_by: {employee: {organization: {name: asc}, employeeRole: {role: {name: asc}}, name: asc}}) {
      id
      description
      employee {
        id
        name
        assets
        employeeRoles {
          role {
            id
            name
          }
        }
        organization {
          id
          name
        }
      }
    }
  }
}
    `;

    export function useDailyLogEmployeeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogEmployeeListQuery, Types.DailyLogEmployeeListQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogEmployeeListQuery, Types.DailyLogEmployeeListQueryVariables>(DailyLogEmployeeListDocument, baseOptions);
    };
      export function useDailyLogEmployeeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogEmployeeListQuery, Types.DailyLogEmployeeListQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogEmployeeListQuery, Types.DailyLogEmployeeListQueryVariables>(DailyLogEmployeeListDocument, baseOptions);
      };
      
export type DailyLogEmployeeListQueryHookResult = ReturnType<typeof useDailyLogEmployeeListQuery>;
export type DailyLogEmployeeListQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogEmployeeListQuery, Types.DailyLogEmployeeListQueryVariables>;
export const DailyLogOrganizationListDocument = gql`
    query DailyLogOrganizationList {
  organizations(where: {deletedAt: {_is_null: true}}) {
    id
    value: id
    label: name
  }
}
    `;

    export function useDailyLogOrganizationListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogOrganizationListQuery, Types.DailyLogOrganizationListQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogOrganizationListQuery, Types.DailyLogOrganizationListQueryVariables>(DailyLogOrganizationListDocument, baseOptions);
    };
      export function useDailyLogOrganizationListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogOrganizationListQuery, Types.DailyLogOrganizationListQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogOrganizationListQuery, Types.DailyLogOrganizationListQueryVariables>(DailyLogOrganizationListDocument, baseOptions);
      };
      
export type DailyLogOrganizationListQueryHookResult = ReturnType<typeof useDailyLogOrganizationListQuery>;
export type DailyLogOrganizationListQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogOrganizationListQuery, Types.DailyLogOrganizationListQueryVariables>;
export const EmployeeListDocument = gql`
    query EmployeeList($excludeDailyLogId: uuid!) {
  employees {
    id
    deletedAt
    icon
    assets
    name
    organization {
      id
      label: name
      value: id
    }
    phone
    employeeRole {
      role {
        id
        label: name
        value: id
      }
    }
    employeeTeams {
      team {
        id
        label: name
        value: id
      }
    }
  }
}
    `;

    export function useEmployeeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EmployeeListQuery, Types.EmployeeListQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.EmployeeListQuery, Types.EmployeeListQueryVariables>(EmployeeListDocument, baseOptions);
    };
      export function useEmployeeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EmployeeListQuery, Types.EmployeeListQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.EmployeeListQuery, Types.EmployeeListQueryVariables>(EmployeeListDocument, baseOptions);
      };
      
export type EmployeeListQueryHookResult = ReturnType<typeof useEmployeeListQuery>;
export type EmployeeListQueryResult = ApolloReactCommon.QueryResult<Types.EmployeeListQuery, Types.EmployeeListQueryVariables>;
export const DailyLogsByIdEmployeesDocument = gql`
    query DailyLogsByIdEmployees($dailyLogId: uuid!) {
  dailyLogEmployeeData: dailyLogs_by_pk(id: $dailyLogId) {
    id
    dailyLogEmployees {
      id
      employeeId
    }
  }
}
    `;

    export function useDailyLogsByIdEmployeesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogsByIdEmployeesQuery, Types.DailyLogsByIdEmployeesQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogsByIdEmployeesQuery, Types.DailyLogsByIdEmployeesQueryVariables>(DailyLogsByIdEmployeesDocument, baseOptions);
    };
      export function useDailyLogsByIdEmployeesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogsByIdEmployeesQuery, Types.DailyLogsByIdEmployeesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogsByIdEmployeesQuery, Types.DailyLogsByIdEmployeesQueryVariables>(DailyLogsByIdEmployeesDocument, baseOptions);
      };
      
export type DailyLogsByIdEmployeesQueryHookResult = ReturnType<typeof useDailyLogsByIdEmployeesQuery>;
export type DailyLogsByIdEmployeesQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogsByIdEmployeesQuery, Types.DailyLogsByIdEmployeesQueryVariables>;
export const RoleListDocument = gql`
    query RoleList {
  roles {
    id
    name
    deletedAt
  }
}
    `;

    export function useRoleListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.RoleListQuery, Types.RoleListQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.RoleListQuery, Types.RoleListQueryVariables>(RoleListDocument, baseOptions);
    };
      export function useRoleListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.RoleListQuery, Types.RoleListQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.RoleListQuery, Types.RoleListQueryVariables>(RoleListDocument, baseOptions);
      };
      
export type RoleListQueryHookResult = ReturnType<typeof useRoleListQuery>;
export type RoleListQueryResult = ApolloReactCommon.QueryResult<Types.RoleListQuery, Types.RoleListQueryVariables>;
export const DailyLogEquipmentListDocument = gql`
    query DailyLogEquipmentList($dailyLogId: uuid!) {
  dailyLog: dailyLogs_by_pk(id: $dailyLogId) {
    id
    status
    placeholders: dailyLogPlaceholders(where: {equipmentTypeId: {_is_null: false}}, order_by: {organization: {name: asc}, equipmentType: {name: asc}}) {
      id
      description
      equipmentType {
        id
        name
      }
      organization {
        id
        name
      }
    }
    dailyLogEquipments(order_by: {equipment: {organization: {name: asc}, equipmentType: {name: asc}}}) {
      id
      description
      equipment {
        id
        name
        assets
        equipmentType {
          id
          name
        }
        organization {
          id
          name
        }
      }
    }
  }
}
    `;

    export function useDailyLogEquipmentListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogEquipmentListQuery, Types.DailyLogEquipmentListQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogEquipmentListQuery, Types.DailyLogEquipmentListQueryVariables>(DailyLogEquipmentListDocument, baseOptions);
    };
      export function useDailyLogEquipmentListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogEquipmentListQuery, Types.DailyLogEquipmentListQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogEquipmentListQuery, Types.DailyLogEquipmentListQueryVariables>(DailyLogEquipmentListDocument, baseOptions);
      };
      
export type DailyLogEquipmentListQueryHookResult = ReturnType<typeof useDailyLogEquipmentListQuery>;
export type DailyLogEquipmentListQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogEquipmentListQuery, Types.DailyLogEquipmentListQueryVariables>;
export const EquipmentListDocument = gql`
    query EquipmentList {
  equipments {
    id
    deletedAt
    icon
    assets
    name
    organization {
      id
      label: name
      value: id
    }
    equipmentType {
      id
      name
    }
    team {
      id
      name
    }
  }
}
    `;

    export function useEquipmentListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EquipmentListQuery, Types.EquipmentListQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.EquipmentListQuery, Types.EquipmentListQueryVariables>(EquipmentListDocument, baseOptions);
    };
      export function useEquipmentListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EquipmentListQuery, Types.EquipmentListQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.EquipmentListQuery, Types.EquipmentListQueryVariables>(EquipmentListDocument, baseOptions);
      };
      
export type EquipmentListQueryHookResult = ReturnType<typeof useEquipmentListQuery>;
export type EquipmentListQueryResult = ApolloReactCommon.QueryResult<Types.EquipmentListQuery, Types.EquipmentListQueryVariables>;
export const EquipmentTypeListDocument = gql`
    query EquipmentTypeList {
  equipmentTypes(where: {deletedAt: {_is_null: true}}) {
    id
    name
    deletedAt
  }
}
    `;

    export function useEquipmentTypeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.EquipmentTypeListQuery, Types.EquipmentTypeListQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.EquipmentTypeListQuery, Types.EquipmentTypeListQueryVariables>(EquipmentTypeListDocument, baseOptions);
    };
      export function useEquipmentTypeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.EquipmentTypeListQuery, Types.EquipmentTypeListQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.EquipmentTypeListQuery, Types.EquipmentTypeListQueryVariables>(EquipmentTypeListDocument, baseOptions);
      };
      
export type EquipmentTypeListQueryHookResult = ReturnType<typeof useEquipmentTypeListQuery>;
export type EquipmentTypeListQueryResult = ApolloReactCommon.QueryResult<Types.EquipmentTypeListQuery, Types.EquipmentTypeListQueryVariables>;
export const DailyLogProjectActivitiesDocument = gql`
    query DailyLogProjectActivities {
  projectActivities(where: {product_Activity: {activity: {deletedAt: {_is_null: true}}, product: {deletedAt: {_is_null: true}, sector: {deletedAt: {_is_null: true}}}}}) {
    projectId
    product_Activity {
      activity {
        name
        id
      }
      product {
        sector {
          id
          name
        }
        id
        name
        deletedAt
      }
    }
  }
}
    `;

    export function useDailyLogProjectActivitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogProjectActivitiesQuery, Types.DailyLogProjectActivitiesQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogProjectActivitiesQuery, Types.DailyLogProjectActivitiesQueryVariables>(DailyLogProjectActivitiesDocument, baseOptions);
    };
      export function useDailyLogProjectActivitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogProjectActivitiesQuery, Types.DailyLogProjectActivitiesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogProjectActivitiesQuery, Types.DailyLogProjectActivitiesQueryVariables>(DailyLogProjectActivitiesDocument, baseOptions);
      };
      
export type DailyLogProjectActivitiesQueryHookResult = ReturnType<typeof useDailyLogProjectActivitiesQuery>;
export type DailyLogProjectActivitiesQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogProjectActivitiesQuery, Types.DailyLogProjectActivitiesQueryVariables>;
export const DailyLogPreviewsDocument = gql`
    query DailyLogPreviews($dailyLogId: uuid!) {
  activitiesPreview: jobs_aggregate(where: {dailyLogId: {_eq: $dailyLogId}}) {
    aggregate {
      count
    }
  }
  employeesPreview: dailyLogEmployees_aggregate(where: {dailyLogId: {_eq: $dailyLogId}}) {
    aggregate {
      count
    }
  }
  placeholdersEmployeePreview: dailyLogPlaceholders_aggregate(where: {dailyLogId: {_eq: $dailyLogId}, _and: {roleId: {_is_null: false}}}) {
    aggregate {
      count
    }
  }
  equipmentsPreview: dailyLogEquipments_aggregate(where: {dailyLogId: {_eq: $dailyLogId}}) {
    aggregate {
      count
    }
  }
  placeholdersEquipmentPreview: dailyLogPlaceholders_aggregate(where: {dailyLogId: {_eq: $dailyLogId}, _and: {equipmentTypeId: {_is_null: false}}}) {
    aggregate {
      count
    }
  }
  materialsPreview: dailyLogMaterials_aggregate(where: {dailyLogId: {_eq: $dailyLogId}}) {
    aggregate {
      count
    }
  }
  documentsPreview: documents_aggregate(where: {dailyLogId: {_eq: $dailyLogId}}) {
    aggregate {
      count
    }
  }
  commentsPreview: comments_aggregate(where: {dailyLogId: {_eq: $dailyLogId}}) {
    aggregate {
      count
    }
  }
}
    `;

    export function useDailyLogPreviewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogPreviewsQuery, Types.DailyLogPreviewsQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogPreviewsQuery, Types.DailyLogPreviewsQueryVariables>(DailyLogPreviewsDocument, baseOptions);
    };
      export function useDailyLogPreviewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogPreviewsQuery, Types.DailyLogPreviewsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogPreviewsQuery, Types.DailyLogPreviewsQueryVariables>(DailyLogPreviewsDocument, baseOptions);
      };
      
export type DailyLogPreviewsQueryHookResult = ReturnType<typeof useDailyLogPreviewsQuery>;
export type DailyLogPreviewsQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogPreviewsQuery, Types.DailyLogPreviewsQueryVariables>;
export const DailyLogsDataDocument = gql`
    query DailyLogsData($date: date!) {
  dailyLogs_aggregate(where: {date: {_eq: $date}}) {
    aggregate {
      count
    }
  }
  dailyLogs(where: {date: {_eq: $date}}) {
    id
    status
    dailyLogEmployees {
      id
      employee {
        id
        name
      }
    }
    team {
      color
      id
      name
      teamLeader {
        id
        name
        assets
      }
    }
    title
    description
    dailyLogProjects {
      project {
        code
        color
        id
        name
        location
      }
    }
  }
}
    `;

    export function useDailyLogsDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.DailyLogsDataQuery, Types.DailyLogsDataQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.DailyLogsDataQuery, Types.DailyLogsDataQueryVariables>(DailyLogsDataDocument, baseOptions);
    };
      export function useDailyLogsDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.DailyLogsDataQuery, Types.DailyLogsDataQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.DailyLogsDataQuery, Types.DailyLogsDataQueryVariables>(DailyLogsDataDocument, baseOptions);
      };
      
export type DailyLogsDataQueryHookResult = ReturnType<typeof useDailyLogsDataQuery>;
export type DailyLogsDataQueryResult = ApolloReactCommon.QueryResult<Types.DailyLogsDataQuery, Types.DailyLogsDataQueryVariables>;
export const CurrentLocationDocument = gql`
    query CurrentLocation($organizationId: uuid!) {
  organizations_by_pk(id: $organizationId) {
    id
    location
  }
}
    `;

    export function useCurrentLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.CurrentLocationQuery, Types.CurrentLocationQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.CurrentLocationQuery, Types.CurrentLocationQueryVariables>(CurrentLocationDocument, baseOptions);
    };
      export function useCurrentLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.CurrentLocationQuery, Types.CurrentLocationQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.CurrentLocationQuery, Types.CurrentLocationQueryVariables>(CurrentLocationDocument, baseOptions);
      };
      
export type CurrentLocationQueryHookResult = ReturnType<typeof useCurrentLocationQuery>;
export type CurrentLocationQueryResult = ApolloReactCommon.QueryResult<Types.CurrentLocationQuery, Types.CurrentLocationQueryVariables>;
export const UserDataDocument = gql`
    query UserData($firebaseId: String!) {
  users(where: {firebaseId: {_eq: $firebaseId}}) {
    id
    configuration
    employees {
      id
      name
      phone
      icon
      assets
      permission
      organization {
        id
        name
      }
      company {
        id
        organizations(where: {self: {_eq: true}}) {
          id
          name
        }
      }
    }
  }
}
    `;

    export function useUserDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.UserDataQuery, Types.UserDataQueryVariables>) {
      return ApolloReactHooks.useQuery<Types.UserDataQuery, Types.UserDataQueryVariables>(UserDataDocument, baseOptions);
    };
      export function useUserDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.UserDataQuery, Types.UserDataQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<Types.UserDataQuery, Types.UserDataQueryVariables>(UserDataDocument, baseOptions);
      };
      
export type UserDataQueryHookResult = ReturnType<typeof useUserDataQuery>;
export type UserDataQueryResult = ApolloReactCommon.QueryResult<Types.UserDataQuery, Types.UserDataQueryVariables>;