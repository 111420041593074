import { SwipeableDrawerProps } from '@material-ui/core/SwipeableDrawer';
import { Action, action } from 'easy-peasy';

type SwipeableDrawerPropsOmit = Omit<SwipeableDrawerProps, 'onOpen'|'onClose' >;

export interface DrawerModel {
  props: SwipeableDrawerPropsOmit;
  drawerState: any;
  setState: Action<DrawerModel, object>;
  setDrawer: Action<DrawerModel, Omit<SwipeableDrawerProps, 'open' | 'onOpen'>>;
  removeDrawer: Action<DrawerModel, void>;
}

const defaultProps: SwipeableDrawerPropsOmit = {
  children: null,
  open: false
};

const model: DrawerModel = {
  props: defaultProps,
  drawerState: {},
  setState: action((state, props) => ({ ...state, drawerState: props })),
  setDrawer: action((state, props) => ({
    ...state,
    props: {
      ...props,
      open: true
    }
  })),
  removeDrawer: action(state => ({
    ...state,
    props: {
      ...state.props,
      open: false
    }
  }))
};

export default model;
