import styled, { css } from 'styled-components';

const Button = styled.button<{ green?: boolean, disabled?: boolean }>`
  padding: 0 36px;
  height: 36px;
  box-shadow: 0 3px 3px rgba(0, 166, 255, 0.38);
  border-radius: 18px;
  background-color: #00a6ff;
  border: none;
  &:disabled {
    background-color: #d7dde6;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    color: #343f4b;
  }
  ${props =>
    !props.disabled &&
    css`
      &:hover {
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
        background-color: #00a6ff;
      }
      &:active {
        box-shadow: 0 3px 3px rgba(0, 166, 255, 0.38);
        background-color: #008bd5;
      }
      &:focus {
        outline: none;
      }
    `} text-transform: uppercase;

  ${props =>
    props.green &&
    css`
      background-color: #13ce66;
    `};
  color: white;
  font-family: Roboto;
  font-size: 14px;
  white-space: nowrap;
`;

export default Button;
