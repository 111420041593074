import { DialogProps } from '@material-ui/core/Dialog';
import { Action, action } from 'easy-peasy';

export interface AddFileDialogModel {
  props: DialogProps;
  addFileDialogState: any;
  setState: Action<AddFileDialogModel, object>;
  openAddFileDialog: Action<AddFileDialogModel, void>;
  removeAddFileDialog: Action<AddFileDialogModel, void>;
}

const defaultProps: DialogProps = {
  children: null,
  open: false
};

const model: AddFileDialogModel = {
  props: defaultProps,
  addFileDialogState: {},
  setState: action((state, props) => ({ ...state, addFileDialogState: props })),
  openAddFileDialog: action(state => ({
    ...state,
    props: {
      ...state.props,
      open: true
    }
  })),
  removeAddFileDialog: action(state => ({
    ...state,
    props: {
      ...state.props,
      open: false
    }
  }))
};

export default model;
