import { PopperProps } from '@material-ui/core/Popper';
import { Action, action } from 'easy-peasy';

export interface PopperModel {
  props: PopperProps;
  setPopper: Action<PopperModel, Omit<PopperProps, 'open'>>;
  removePopper: Action<PopperModel, void>;
}

const defaultProps: PopperProps = {
  children: null,
  open: false
};

const model: PopperModel = {
  props: defaultProps,
  setPopper: action((state, props) => ({
    ...state,
    props: {
      ...props,
      open: true
    }
  })),
  removePopper: action(state => ({
    ...state,
    props: {
      ...state.props,
      open: false
    }
  }))
};

export default model;
