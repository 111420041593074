import administrator from './administrator';
import fieldWorker from './fieldWorker';
import master from './master';
import organizer from './organizer';
import resourcesManager from './resourcesManager';
import scheduler from './scheduler';
import teamLeader from './teamLeader';

export { default as administrator } from './administrator';
export { default as fieldWorker } from './fieldWorker';
export { default as organizer } from './organizer';
export { default as master } from './master';
export { default as resourcesManager } from './resourcesManager';
export { default as scheduler } from './scheduler';
export { default as teamLeader } from './teamLeader';

type permissionValue = 'administrator' | 'organizer' | 'master' | 'resourcesManager' | 'scheduler' | 'teamLeader' | 'fieldWorker';
export function getPermission(permission: permissionValue): Permission {
  switch (permission) {
    case 'administrator':
      return administrator;
    case 'organizer':
      return organizer;
    case 'master':
      return master;
    case 'resourcesManager':
      return resourcesManager;
    case 'scheduler':
      return scheduler;
    case 'teamLeader':
      return teamLeader;
    case 'fieldWorker':
      return fieldWorker;
  }
}
