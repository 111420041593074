import Uppy from '@uppy/core';
// import EnglishUppy from '@uppy/locales/lib/en_US';
import FrenchUppy from '@uppy/locales/lib/fr_FR';
import Webcam from '@uppy/webcam';
import firebase from 'firebase/app';
import { Formik, FormikActions } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import FirebaseCloudStorage from './uppyCustomPlugin/FirebaseCloudStorage';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';

import { Dashboard } from '@uppy/react';
import Button from 'Components/atoms/Button_portal';
import { CloseIconButton } from 'Components/atoms/ManyIconButton_portal';
import Select from 'Components/atoms/Select_portal';
import { FoldingCube } from 'styled-spinkit';

import { useDailyLogsByIdProjectListQuery } from 'apollo/hooks';
import t from 'Helpers/translations';
import * as dailyLogDocumentService from 'Services/dailyLogService/dailyLogDocumentService';
import { useStoreState } from 'Store/index';

interface Props {
  onClose: () => void;
  dailyLogProjects?: { id: string, name: string }[];
}

interface FormValues {
  project?: {  value: string; label: string; } | null;
  files: Uppy.UppyFile[];
}

export default function AddFileDialog({ onClose }: Props) {
  const addFileDialogState = useStoreState(state => state.addFileDialog.addFileDialogState);
  const dailyLogId = addFileDialogState && addFileDialogState.dailyLogId;
  const dailyLogsByIdProjectListResponse = useDailyLogsByIdProjectListQuery({ variables: { dailyLogId }, skip: !addFileDialogState });

  const { company, employeeId } = useStoreState(state => state.user);
  const [uppy, setUppy] = useState<Uppy.Uppy | null>(null);

  useEffect(() => {
    if (firebase && !uppy) {
      const newUppy = Uppy({
        locale: FrenchUppy, // TODO: AppLanguage.get() === AvailableLanguages.French ? FrenchUppy : EnglishUppy,
        debug: false,
        autoProceed: true,
        restrictions: {
          maxFileSize: 10000000,
          maxNumberOfFiles: null,
          minNumberOfFiles: null,
          allowedFileTypes: null
        }
      });
      newUppy.use(Webcam, { id: 'Webcam', facingMode: 'environment', mirror: false });
      newUppy.use(FirebaseCloudStorage, {
        id: 'FirebaseCloudStorage',
        storageRef: firebase
          .storage()
          .ref('documents')
      });
      setUppy(newUppy);
    }
  }, [uppy]);

  if (dailyLogsByIdProjectListResponse.error) {
    return <span>{t('uppy_form_error_query')}</span>;
  }

  if (!uppy || dailyLogsByIdProjectListResponse.loading || !dailyLogsByIdProjectListResponse.data) {
    return (
      <FoldingCube color='#ffecd1' size={80} />
    );
  }

  if (!dailyLogsByIdProjectListResponse.data.dailyLog) {
    return <span>{t('uppy_form_daily_log_not_exist')}</span>;
  }

  const dailyLogProjects = dailyLogsByIdProjectListResponse.data.dailyLog.dailyLogProjects
  .map(dailyLogProject => ({ id: dailyLogProject.project.id, name: dailyLogProject.project.name }));

  const onSaveDocuments = async (files: (Uppy.UppyFile & { downloadUrl: string })[], projectId: string | null) => {
    await dailyLogDocumentService.addMultipleDocuments({
      documents: _.map(files, file => ({
        name: file.name,
        url: file.downloadUrl,
        showInDailyLogs: false,
        metadata: { type: file.meta.type }
      })),
      companyId: company.id,
      projectId,
      dailyLogId,
      employeeId
    });
  };

  const handleOnSubmit = async (values: FormValues, { setSubmitting, resetForm }: FormikActions<FormValues>) => {
    setSubmitting(true);
    await onSaveDocuments([...values.files] as (Uppy.UppyFile & { downloadUrl: string })[], values.project ? values.project.value : null);
    setSubmitting(false);
    handleClose();
    resetForm();
    if (addFileDialogState.refetchData) {
      addFileDialogState.refetchData();
    }
  };

  const handleClose = () => {
    uppy.reset();
    onClose();
  };

  const initialValues: FormValues = dailyLogProjects ? {
    project: dailyLogProjects.length === 1 ? { value: dailyLogProjects[0].id, label: dailyLogProjects[0].name } : null,
    files: []
  } : {
    files: []
  };

  const validationSchema = dailyLogProjects ?
    yup.object().shape({
      project: yup.object().nullable(true).required('You must choose a project'),
      files: yup.array().of(yup.object()).nullable(true).required('You must upload a document')
    }) :
    yup.object().shape({
      files: yup.array().of(yup.object()).nullable(true).required('You must upload a document')
    });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      {formikProps => {
        const { values, touched, errors, setFieldValue, setFieldTouched, submitForm } = formikProps;

        uppy.on('complete', () => {
          setFieldTouched('files', true);
          setFieldValue('files', uppy.getFiles());
        });

        const handleSetFieldTouched = (fieldName: string) => () => setFieldTouched(fieldName, true);
        const handleSetFieldValue = (fieldName: string) => (value: object) => setFieldValue(fieldName, value);

        const saveButton = (values.files.length > 0) && (
          <Button onClick={submitForm}>{t('button_save')}</Button>
        );

        const options = _.map(dailyLogProjects, dailyLogProject => ({ value: dailyLogProject.id, label: dailyLogProject.name }));

        const selectRow = (dailyLogProjects && dailyLogProjects.length > 1) && (
          <SelectRow
            options={options}
            value={values.project}
            isError={!!(errors.project && touched.project)}
            errorMessage={errors.project}
            onBlur={handleSetFieldTouched('project')}
            onChange={handleSetFieldValue('project')}
          />
        );

        return (
          <Container>
            <Header>
              <Title>{t('add_document_dialog_title')}</Title>
              <Spacer />
              <StyledCloseIconButton onClick={handleClose} />
            </Header>
            <Content>
              {
                uppy &&
                <Dashboard
                  plugins={['Webcam']}
                  uppy={uppy}
                  hideUploadButton
                  proudlyDisplayPoweredByUppy={false}
                />
              }
              {selectRow}
            </Content>
            <Footer>
              <BigButtonCancel onClick={onClose}>{t('cancel_button_label')}</BigButtonCancel>
              <Spacer />
              {saveButton}
            </Footer>
          </ Container>
        );
      }}
    </Formik>
  );
}

interface SelectRowProps {
  options: LabeledData[];
  value?: LabeledData | null;
  isError: boolean;
  errorMessage?: string;
  onBlur: () => void;
  onChange: (value: object) => void;
}

function SelectRow({ options, value, isError, errorMessage, onBlur, onChange }: SelectRowProps) {
  return (
    <>
      <FieldRow>
        <FieldLabel>{'Project'}</FieldLabel>
        <Select
          name={'project'}
          options={options}
          value={value}
          isClearable={false}
          isError={isError}
          onBlur={onBlur}
          onChange={onChange}
        />
      </FieldRow>
      {isError &&
        <ErrorMessage>{errorMessage}</ ErrorMessage>
      }
    </>
  );
}

// Styled Components

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;

  > div {
    height: 100%;
  }
  .uppy-Dashboard-inner{
    height: 100% !important;
  }
  .uppy-Root{
    height: 100% !important;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const Title = styled.div`
  color: #343F4B;
  font-size: 14px;
  font-family: Roboto;
`;

const Header = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
`;

const Footer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 5px 0;
`;

const StyledCloseIconButton = styled(CloseIconButton)`
  &&{
    height: 30px;
    width: 30px;
    padding: 0;
  }
`;

const ErrorMessage = styled.div`
  font-family: Roboto, Regular;
  font-size: 13px;
  color: #f95f62;
  margin-top: 7px;
  position: relative;
  margin-left: 131px;
`;

const FieldRow = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

const FieldLabel = styled.div`
  width: 115px;
  text-align: right;
  font-family: Roboto, Regular;
  font-size: 12px;
  color: #343f4b;
  margin-right: 16px;
`;

const BigButtonCancel = styled.div`
  height: 32px;
  min-height: 32px;
  border-radius: 16px;
  padding: 0 27px;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
  float: 'left';
  margin-right: '20px';
  color: #f95f62;
  background-color: transparent;
  box-shadow: none;
  &:hover{
    color: #e0393d;
    background-color: transparent;
  }
  margin-right:20px;
`;
