const fieldWorker: Permission = {
  order: 6,
  label: 'Field Worker',
  rules: {
    dashboard: { view: true, edit: false, viewOwnTeamOnly: false, viewSubPage: false },
    documents: { view: false, create: false, edit: false, delete: false },
    timeline: { view: false, create: false, viewOwnTeamOnly: false, edit: false, delete: false, restore: false, permanentDelete: false },
    dailyLog: {
      view: false,
      create: false,
      edit: false,
      delete: false,
      restore: false,
      permanentDelete: false,
      viewSubPage: false,
      previousStatus: false,
      sendSMS: false,
      documents: { view: false, create: false, edit: false, delete: false }
    },
    priceAnalysis: { view: false, edit: false },
    map: { view: true },
    projectPage: { view: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    projectList: { view: true, viewSameOrganizationOnly: true, create: false, edit: false, delete: false, restore: false, permanentDelete: false, viewSubPage: false },
    projectDescription: { view: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    projectAnalysis: { view: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    report: { view: false, viewSelfOnly: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    organizations: { view: true, create: false, edit: false, delete: false, restore: false, permanentDelete: false, viewSubPage: false },
    organizationPage: { view: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    activities: { view: true, create: false, edit: false, delete: false, restore: false, permanentDelete: false, editActivityPriceType: false },
    materials: { view: true, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    equipments: { view: true, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    employees: { view: true, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    teams: { view: true, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    administration: { view: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false }
  },
  value: 'fieldWorker'
};

export default fieldWorker;
