import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import _ from 'lodash';
import React from 'react';
import { useStoreActions, useStoreState } from 'Store/index';
import styled from 'styled-components';

const GlobalDrawer = () => {
  const props = useStoreState(state => state.drawer.props);

  return (
    <StyledSwipeableDrawer {...props} disableDiscovery disableSwipeToOpen />
  );
};

const StyledSwipeableDrawer = styled(SwipeableDrawer)<any>`
  & .MuiBackdrop-root {
    background-color: rgba(61, 85, 209, 0.75);
  }
  & .MuiDrawer-paperAnchorBottom {
    border-radius: 12px 12px 0 0;
    height: 225px;
  }
  z-index: 9000;
`;

export default GlobalDrawer;
