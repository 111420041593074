const administrator: Permission = {
  order: 0,
  label: 'Administrator',
  rules: {
    dashboard: { view: true, edit: true, viewOwnTeamOnly: false, viewSubPage: true },
    documents: { view: true, create: true, edit: true, delete: true },
    timeline: { view: true, create: true, viewOwnTeamOnly: false, edit: true, delete: true, restore: true, permanentDelete: true },
    dailyLog: {
      view: true,
      create: true,
      edit: true,
      delete: true,
      restore: true,
      permanentDelete: true,
      viewSubPage: true,
      previousStatus: true,
      sendSMS: true,
      documents: { view: true, create: true, edit: true, delete: true }
    },
    priceAnalysis: { view: true, edit: true },
    map: { view: true },
    projectPage: { view: true, create: true, edit: true, delete: true, restore: true, permanentDelete: true },
    projectList: { view: true, viewSameOrganizationOnly: false, create: true, edit: true, delete: true, restore: true, permanentDelete: true, viewSubPage: true },
    projectDescription: { view: true, create: true, edit: true, delete: true, restore: true, permanentDelete: true },
    projectAnalysis: { view: true, create: true, edit: true, delete: true, restore: true, permanentDelete: true },
    report: { view: true, viewSelfOnly: false, create: true, edit: true, delete: true, restore: true, permanentDelete: true },
    organizations: { view: true, create: true, edit: true, delete: true, restore: true, permanentDelete: true, viewSubPage: true },
    organizationPage: { view: true, create: true, edit: true, delete: true, restore: true, permanentDelete: true },
    activities: { view: true, create: true, edit: true, delete: true, restore: true, permanentDelete: true, editActivityPriceType: true },
    materials: { view: true, create: true, edit: true, delete: true, restore: true, permanentDelete: true },
    equipments: { view: true, create: true, edit: true, delete: true, restore: true, permanentDelete: true },
    employees: { view: true, create: true, edit: true, delete: true, restore: true, permanentDelete: true },
    teams: { view: true, create: true, edit: true, delete: true, restore: true, permanentDelete: true },
    administration: { view: true, create: true, edit: true, delete: true, restore: true, permanentDelete: true }
  },
  value: 'administrator'
};

export default administrator;
