import MobileDetect from 'mobile-detect';
import React from 'react';
import ReactSelect from 'react-select';

import styled from 'styled-components';
import selectStyle from './selectStyle';

interface Props {
  name?: string;
  options: any; // Array<any & { label: string; value: string }>;
  isDisabled?: boolean;
  isError?: boolean;
  isMulti?: boolean;
  defaultValue?: any; // { label: string; value: string };
  onChange: any;
  onBlur?: any;
  value?: any | any[];
  skipSort?: boolean;
  inlineStyle?: any;
  placeholder?: string;
  isClearable?: boolean;
}

const Select = (props: Props) => {
  const { skipSort, options, inlineStyle, onChange, placeholder, name, defaultValue, value = {}, isDisabled = false, isError = false, isMulti = false, onBlur, isClearable = false } = props;
  const selectOptions = [].concat(options);

  const isDesktop = !md.mobile();

  const onHandleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (onBlur) {
      onBlur();
    }
  };

  if (!skipSort) {
    selectOptions.sort(compareByLabel);
  }

  return (
    <StyledSelect
      name={name}
      options={selectOptions}
      isSearchable={isDesktop}
      className=''
      classNamePrefix=''
      isMulti={isMulti}
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      isClearable={isClearable}
      value={value}
      styles={selectStyle(isError, inlineStyle)}
      onChange={onChange}
      onBlur={onHandleBlur}
      placeholder={placeholder}
      menuPosition='fixed'
    />
  );
};

const md = new MobileDetect(window.navigator.userAgent);

// styled-components

const StyledSelect = styled(ReactSelect)`
  max-width: 300px;
  min-width: 225px;
`;

export default Select;

export function compareByLabel(a: any, b: any) {
  if (a.active && !b.active) {
    return -1;
  }
  if (!a.active && b.active) {
    return 1;
  }

  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1;
  }
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1;
  }
  return 0;
}
