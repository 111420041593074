import { createStore, createTypedHooks } from 'easy-peasy';

// Models
import addFileDialogModel, { AddFileDialogModel } from './models/addFileDialogModel';
import dialogModel, { DialogModel } from './models/dialogModel';
import drawerModel, { DrawerModel } from './models/drawerModel';
import formModel, { FormModel } from './models/formModel';
import menuModel, { MenuModel } from './models/menuModel';
import popperModel, { PopperModel } from './models/popperModel';
import userModel, { UserModel } from './models/userModel';

export interface Model {
  addFileDialog: AddFileDialogModel;
  dialog: DialogModel;
  drawer: DrawerModel;
  form: FormModel;
  menu: MenuModel;
  popper: PopperModel;
  user: UserModel;
}

export interface ConnectedModel {
  addFileDialog: AddFileDialogModel;
  dialog: DialogModel;
  drawer: DrawerModel;
  form: FormModel;
  menu: MenuModel;
  popper: PopperModel;
  user: Required<UserModel>;
}

export default createStore<Model>(
  {
    addFileDialog: addFileDialogModel,
    dialog: dialogModel,
    drawer: drawerModel,
    form: formModel,
    menu: menuModel,
    popper: popperModel,
    user: userModel
  },
  {
    disableImmer: true
  }
);

export const {
  useStoreActions,
  useStoreState,
  useStoreDispatch
} = createTypedHooks<ConnectedModel>();
