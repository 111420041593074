import { Action, action } from 'easy-peasy';
import firebase from 'firebase';

export interface FormProps {
  type: 'create' | 'edit';
  selectedPage?: string;
  title: string;
  firebase?: typeof firebase;
  buttonText: string;
  fields: any[];
  onUpsert: any;
  defaultValues: any;
  open: boolean;
}

export interface FormModel {
  props: FormProps;
  setForm: Action<FormModel, Omit<FormProps, 'open'>>;
  removeForm: Action<FormModel, void>;
}

const defaultProps: FormProps = {
  defaultValues: '',
  onUpsert: () => true,
  fields: [],
  open: false,
  buttonText: 'Add',
  title: '',
  type: 'create'
};

const model: FormModel = {
  props: defaultProps,
  setForm: action((state, props) => ({
    ...state,
    props: {
      ...props,
      open: true
    }
  })),
  removeForm: action(state => ({
    ...state,
    props: {...state.props,
      open: false
    }
  }))
};

export default model;
