const organizer: Permission = {
  order: 2,
  label: 'Organizer',
  rules: {
    dashboard: { view: true, edit: true, viewOwnTeamOnly: false, viewSubPage: true },
    documents: { view: true, create: true, edit: true, delete: false },
    timeline: { view: true, create: true, viewOwnTeamOnly: false, edit: true, delete: true, restore: false, permanentDelete: false },
    dailyLog: {
      view: true,
      create: true,
      edit: true,
      delete: true,
      restore: false,
      permanentDelete: false,
      viewSubPage: true,
      previousStatus: false,
      sendSMS: true,
      documents: { view: true, create: true, edit: true, delete: true }
    },
    priceAnalysis: { view: true, edit: true },
    map: { view: true },
    projectPage: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false },
    projectList: { view: true, viewSameOrganizationOnly: false, create: true, edit: true, delete: true, restore: false, permanentDelete: false, viewSubPage: true },
    projectDescription: { view: true, create: true, edit: true, delete: true, restore: true, permanentDelete: true },
    projectAnalysis: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false },
    report: { view: true, viewSelfOnly: false, create: true, edit: true, delete: true, restore: false, permanentDelete: false },
    organizations: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false, viewSubPage: true },
    organizationPage: { view: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    activities: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false, editActivityPriceType: true },
    materials: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false },
    equipments: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false },
    employees: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false },
    teams: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false },
    administration: { view: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false }
  },
  value: 'organizer'
};

export default organizer;
