import Select from 'Components/atoms/Select_portal';
import _ from 'lodash';
import React, { useState } from 'react';
import CustomUploadButton from 'react-firebase-file-uploader';
import CreatableSelect from 'react-select';
import MaskedInput from 'react-text-mask';
import styled, { css } from 'styled-components';
import { FoldingCube } from 'styled-spinkit';

import Avatar from '@material-ui/core/Avatar';
// import InfoIcon from 'Assets/react/InfoIcon';
import RoundButton from 'Components/atoms/IconButton';
// import LocationField from 'Components/organisms/LocationField';
import firebaseType from 'firebase';
import t from 'Helpers/translations';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
// import PaletteIcon from 'mdi-react/PaletteIcon';
import selectStyle from './selectStyle';

export interface Field {
  id: string;
  name: string;
  placeholder: string;
  type: 'phone' | 'text' | 'picture' | 'textarea' | 'multiselect'
  | 'select' | 'creatable' | 'color' | 'time' | 'location' | 'labeltext'|'number';
  notCreatable?: boolean;
  notEditable?: boolean;
  notEditableIf?: any;
  subSelect?: any;
  required?: boolean;
  disabled?: boolean;
  createSelection?: any[];
  selection?: any[];
  clear?: boolean;
  defaultPicture?: string;
  prefix?: string;
  suffix?: string;
  includeThousandsSeparator?: boolean;
  thousandsSeparatorSymbol?: string;
  allowDecimal?: boolean;
  decimalSymbol?: string;
  decimalLimit?: number;
  integerLimit?: number;
  requireDecimal?: boolean;
  allowNegative?: boolean;
  allowLeadingZeroes?: boolean;
}
interface DialogContentProps {
  firebase?: typeof firebaseType;
  fields: Field[];
  type: 'edit' | 'create';
  // setPopupDateVisible: React.Dispatch<React.SetStateAction<Field | null>>;
  // setPopupColorVisible: React.Dispatch<React.SetStateAction<Field | null>>;

  // FormikProps
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  submitForm: any;
  resetForm: any;
  handleChange: any;
  handleBlur: any;
}

const DialogContent = (props: DialogContentProps) => {
  const {
    // setPopupDateVisible,
    // setPopupColorVisible,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    firebase
  } = props;
  const [fieldsLoading, setFieldsLoading] = useState<any>({});
  const getOptions = (field: any) => {
    if (field.subSelect) {
      return values[field.subSelect.field] ? values[field.subSelect.field][field.subSelect.value] : [];
    }
    return (props.type === 'create' && field.createSelection) || field.selection;
  };

  const onChangeSelect = (field: Field) => (selectChoice: any) => {
    setFieldValue(field.id, selectChoice);
    if (field.clear) {
      setFieldValue(field.clear, null);
    }
  };
  const onBlurSelect = (field: Field) => () => {
    setFieldTouched(field.id, true);
  };
  const onChangeSelectCreatable = (field: Field) => (selectChoice: any) => {
    setFieldValue(field.id, selectChoice ? selectChoice.value : undefined);
  };
  const toggleLoadingField = (id: string, value: boolean) => {
    setFieldsLoading((prevState: any) => {
      const loadings = Object.assign({}, prevState);
      loadings[id] = value;
      return loadings;
    });
  };
  const storeImageToFirebase = async (field: any, filename: string) => {
    if (firebase) {
      await firebase
        .storage()
        .ref('images')
        .child(filename)
        .getDownloadURL()
        .then(url => {
          setFieldValue(field.id, url);
          toggleLoadingField(field.id, false);
        })
        .catch((error: string) => {
          setFieldError(field.id, error);
        });
    }
  };
  const showPopupDate = (field: Field) => () => {
    // if (field.type === 'time') {
    //   setPopupDateVisible(field);
    // }
  };
  // const onChangeLocation = (field: Field) => (newLocation: { label: string, lat: number, lng: number }) => {
  //   const { label, lat, lng } = newLocation;
  //   if (
  //     typeof label === 'string' ||
  //     typeof lat === 'number' ||
  //     typeof lng === 'number'
  //   ) {
  //     setFieldValue(field.id, { ...newLocation });
  //   }
  // };
  // const showPopupColor = (field: Field) => () => {
  //   setPopupColorVisible(field);
  // };
  // const getPermissionIcon = (field: Field) => {
  //   if (field.id === 'permission') {
  //     return (
  //       <StyledInformationIcon data-tip data-for='permission' />
  //     );
  //   }
  // };
  const getComponent = (field: Field) => {
    switch (field.type) {
      case 'textarea':
        return (
          <StyledTextarea
            name={field.id}
            placeholder={field.placeholder}
            value={values[field.id] || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            disable={field.notCreatable ||
              (props.type === 'edit' && (field.notEditable || values[field.notEditableIf]))}
            error={errors[field.id] && touched[field.id]}
          />);
      case 'phone':
        return (
          <PhoneInput
            mask={['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            guide={false}
            name={field.id}
            type='text'
            placeholder={field.placeholder}
            value={values[field.id]}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={props.type === 'edit' && (field.notEditable || values[field.notEditableIf])}
            error={errors[field.id] && touched[field.id]}
          />);
      case 'multiselect':
      case 'select':
        return (
          <InfoSelectorContainer>
            <Select
              name={field.id}
              isMulti={field.type === 'multiselect'}
              options={getOptions(field) || []}
              isDisabled={
                field.disabled ||
                (props.type === 'edit' && field.notEditable) ||
                (field.subSelect != null && values[field.subSelect.field] == null)
              }
              isError={errors[field.id] && touched[field.id]}
              defaultValue={values[field.id]}
              onChange={onChangeSelect(field)}
              value={
                values[field.id] && values[field.id].label !== null
                  ? values[field.id]
                  : null
              }
              onBlur={onBlurSelect(field)}
              placeholder={field.placeholder}
            />
            {/* {getPermissionIcon(field)} */}
          </InfoSelectorContainer>
        );
      case 'creatable':
        return (
          <StyledCreatableSelect
            name={field.id}
            options={(props.type === 'create' && field.createSelection) || field.selection}
            isClearable
            isSearchable
            className=''
            classNamePrefix=''
            menuPosition='fixed'
            isDisabled={props.type === 'edit' && field.notEditable}
            defaultValue={props.values[field.id]}
            styles={selectStyle(props.errors[field.id] && props.touched[field.id])}
            onChange={onChangeSelectCreatable(field)}
            onBlur={onBlurSelect(field)}
            placeholder={field.placeholder}
          />
        );
      case 'picture':
        if (firebase) {
          return (
            <PictureInput
              icon={getImageIcon(field)}
            />
          );
        } else {
          return null;
        }
      // case 'color': // TODO: add when more features is supported and TEST with list component when it going to be virtualized
      //   return (
      //     <ColorContainer>
      //       <ColorInput
      //         onClick={showPopupColor(field)}
      //         backgroundColor={props.values[field.id]}
      //         icon={
      //           <PaletteIcon
      //             size={18}
      //             color={props.values[field.id] ? '#ffffff' : 'rgba(90, 105, 120, 1)'}
      //           />}
      //       />
      //     </ColorContainer>
      //   );
      // case 'location': // TOTEST with list component when it going to be virtualized
      //   return (
      //     <LocationField
      //       name={field.id}
      //       placeholder={field.placeholder}
      //       value={values[field.id] || {}}
      //       onChange={onChangeLocation(field)}
      //       onBlur={onBlurSelect(field)}
      //       disabled={props.type === 'edit' && (field.notEditable || values[field.notEditableIf])}
      //     />
      //   );
      case 'number':
        return(
          <NumberInput
            id={field.id}
            mask={createNumberMask({
              prefix: field.prefix,
              suffix: field.suffix,
              includeThousandsSeparator: field.includeThousandsSeparator,
              thousandsSeparatorSymbol: field.thousandsSeparatorSymbol,
              allowDecimal: field.allowDecimal,
              decimalSymbol: field.decimalSymbol,
              decimalLimit: field.decimalLimit,
              integerLimit: field.integerLimit,
              requireDecimal: field.requireDecimal,
              allowNegative: field.allowNegative,
              allowLeadingZeroes: field.allowLeadingZeroes
            })}
            placeholder={field.placeholder}
            value={values[field.id]}
            onChange={handleChange}
            disable={field.notCreatable ||
              (props.type === 'edit' && (field.notEditable || values[field.notEditableIf]))}
            error={errors[field.id] && touched[field.id]}
          />
        );
      case 'text':
      case 'labeltext':
      case 'time':
      default:
        return (
          <DialogInput
            name={field.id}
            type='text'
            placeholder={field.placeholder}
            value={values[field.id] || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            onClick={showPopupDate(field)}
            disable={field.notCreatable ||
              (props.type === 'edit' && (field.notEditable || values[field.notEditableIf]))
            }
            error={errors[field.id] && touched[field.id]}
          />
        );
    }
  };

  const getAvatarIcon = (field: Field) => {
    if (!fieldsLoading[field.id]) {
      return (
        <div>
          <StyledAvatar
            alt='avatar'
            src={values[field.id] || field.defaultPicture}
          />
        </div>);
    } else if (fieldsLoading[field.id]) {
      return (
        <Loading>
          <FoldingCube color='#ffecd1' size={80} />
        </Loading>
      );
    }
  };

  const getImageIcon = (field: Field) => {
    const handleOnUploadSuccess = async (filename: string) => {
      storeImageToFirebase(field, filename);
    };

    return (
      <CustomUploadButton
        accept='image/*'
        randomizeFilename
        storageRef={firebase && firebase.storage().ref('images')}
        onUploadStart={onUploadStart(field)}
        onUploadSuccess={handleOnUploadSuccess}
      >
        {getAvatarIcon(field)}
        <PictureOverlay>{t('form_change')}</PictureOverlay>
      </CustomUploadButton>
    );
  };

  const onUploadStart = (field: Field) => () => {
    toggleLoadingField(field.id, true);
  };

  const getErrorMessage = (field: Field) => {
    if (errors[field.id] && touched[field.id]) {
      return (
        <ErrorMessage
          id={`Error--${errors[field.id]}`.replace(/[ .@#]/g, '_')}
        >
          {errors[field.id]}
        </ErrorMessage>
      );
    }
  };
  const getOptionalMessage = (field: Field) => {
    if (!errors[field.id] && !field.required) {
      return (
        <OptionalMessage>
          {t('form_optional')}
        </OptionalMessage>
      );
    }
  };
  return (
    <DialogContentList>
      {_.map(props.fields, field => {
        return (
          <DialogFieldRow key={field.id}>
            <DialogFieldRowName>
              {field.type === 'picture' ? t('form_picture') : field.name}
            </DialogFieldRowName>
            <DialogInputGroup>
              {getComponent(field)}
              {getErrorMessage(field)}
              {getOptionalMessage(field)}
            </DialogInputGroup>
          </DialogFieldRow>
        );
      })}
    </DialogContentList>
  );
};

const StyledAvatar = styled(Avatar) <any>`
&&{
  width: 80px !important;
  height: 80px !important;
  cursor: pointer;
}
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
`;
const PictureOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  display: block;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3px;
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
`;

const PictureInput = styled(RoundButton) <any>`
&&{
    position: relative;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 80px !important;
  height: 80px !important;
  cursor: pointer;
}
`;
const StyledCreatableSelect = styled(CreatableSelect) <any>`
  max-width: 300px;
`;

const InfoSelectorContainer = styled.div`
  display:flex;
  flex:2;
  align-items:center;
`;
const DialogContentList = styled.div`
  padding: 0 5px;
  background: #ffffff;
  padding-top: 20px;
`;

const DialogFieldRow = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const DialogFieldRowName = styled.div`
  padding-left: 10px;
  text-align: right;
  font-family: Roboto, Regular;
  font-size: 12px;
  color: #343f4b;
`;

const DialogInputGroup = styled.div`
  margin-left: 18px;
  flex: 1;
`;

const DialogInput = styled.input<{ disable: boolean, error: boolean }> `
  outline: 0;
  font-family: Roboto, Regular;
  font-size: 14px !important;
  height: 40px;
  display: block;
  width: 100%;
  padding: 0 22px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 23px;
  border: 1px solid #e4e8ee;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
&&::placeholder{
  color: rgba(150, 159, 170, 1);
}
${props => props.disable && css`
  background-color: #f3f3f3;
  color: #878787;
`};

  ${props => props.error && css`
    background-color: rgba(249, 95, 98, 0.1);
    border: 1px solid rgba(249, 95, 98, 1);
    && :focus{
      border: 1px solid rgba(249, 95, 98, 1);
      box-shadow: 0 0 0 0.2rem rgba(249, 95, 98, 0.25);
    }
  `};
                `;

const StyledTextarea = styled.textarea<{ disable: boolean, error: boolean }>`
                      outline: 0;
                      font-family: Roboto, Regular;
                      font-size: 14px !important;
                      min-height: 80px;
                      display: block;
                      width: 100%;
                      padding: 0 22px;
                      font-size: 1rem;
                      line-height: 1.5;
                      color: #495057;
                      background-color: #fff;
                      background-clip: padding-box;
                      border-radius: 23px;
                      border: 1px solid #e4e8ee;
                      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &&::placeholder{
                    color: rgba(150, 159, 170, 1);
              }
          ${props => props.disable && css`
    background-color: #f3f3f3;
    color: #878787;
  `};

  ${props => props.error && css`
    background-color: rgba(249, 95, 98, 0.1);
    border: 1px solid rgba(249, 95, 98, 1);
    && :focus{
      border: 1px solid rgba(249, 95, 98, 1);
      box-shadow: 0 0 0 0.2rem rgba(249, 95, 98, 0.25);
    }
  `};
                `;

const NumberInput = styled(MaskedInput) <any>`
                      outline: 0;
                      font-family: Roboto, Regular;
                      font-size: 14px !important;
                      height: 40px;
                      display: block;
                      width: 100%;
                      padding: 0 22px;
                      font-size: 1rem;
                      line-height: 1.5;
                      color: #495057;
                      background-color: #fff;
                      background-clip: padding-box;
                      border-radius: 23px;
                      border: 1px solid #e4e8ee;
                      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &&::placeholder{
                      color: rgba(150, 159, 170, 1);
                  }
  ${props => props.disable && css`
    background-color: #f3f3f3;
    color: #878787;
  `};

  ${props => props.error && css`
    background-color: rgba(249, 95, 98, 0.1);
    border: 1px solid rgba(249, 95, 98, 1);
  && :focus{
    border: 1px solid rgba(249, 95, 98, 1);
    box-shadow: 0 0 0 0.2rem rgba(249, 95, 98, 0.25);
  }
  `};
`;
const PhoneInput = styled(MaskedInput) <any>`
                      outline: 0;
                      font-family: Roboto, Regular;
                      font-size: 14px !important;
                      height: 40px;
                      display: block;
                      width: 100%;
                      padding: 0 22px;
                      font-size: 1rem;
                      line-height: 1.5;
                      color: #495057;
                      background-color: #fff;
                      background-clip: padding-box;
                      border-radius: 23px;
                      border: 1px solid #e4e8ee;
                      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &&::placeholder{
                      color: rgba(150, 159, 170, 1);
                  }
  ${props => props.disable && css`
    background-color: #f3f3f3;
    color: #878787;
  `};

  ${props => props.error && css`
    background-color: rgba(249, 95, 98, 0.1);
    border: 1px solid rgba(249, 95, 98, 1);
  && :focus{
    border: 1px solid rgba(249, 95, 98, 1);
    box-shadow: 0 0 0 0.2rem rgba(249, 95, 98, 0.25);
  }
  `};
                  `;
const ErrorMessage = styled.div`
                    font-family: Roboto, Regular;
                    font-size: 13px;
                    color: #f95f62;
                    margin-top: 7px;
                    position: relative;
                  `;
const OptionalMessage = styled.div`
  font-family: Roboto, Regular;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.3);
  margin-top: 3px;
  position: relative;
`;

// const StyledInformationIcon = styled(InfoIcon)`
// &&{
//   margin-left:20px;
//   width: 20px;
// }
// `;

// const ColorContainer = styled.div`

//                   `;

// const ColorInput = styled(RoundButton) <any>`
//   &&{
//                         background: ${props => props.backgroundColor};
//                       border-radius: 50%;
//                       border: 1px solid #e4e8ee;
//                       width: 40px;
//                       height: 40px;
//                       cursor: pointer;
//     && button{
//                         margin: -1px;
//                     }
//                   }
//                 `;
export default DialogContent;
