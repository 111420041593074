import { Action, action } from 'easy-peasy';
import { AppLanguage, AvailableLanguages } from 'Helpers/translations';

type Organization = {id: string, name: string};
type Company = {id: string, organization: Organization};

export interface UserModel {
  tokenId?: string;
  firebaseId?: string | null;
  userId?: string;
  email?: string;
  configuration?: Configuration;
  employeeId?: string;
  name?: string;
  phone?: string | null;
  assets?: { images?: { base: string, icon?: string, thumb?: string } };
  permission?: Permission;
  userStatus?: string;
  organization?: Organization;
  company?: Company;
  // Actions
  setUserData: Action<UserModel, SetUserDataProps>;
  setConfiguration: Action<UserModel, Configuration>;
}

const model: UserModel = {
  tokenId: undefined,
  firebaseId: undefined,
  userId: undefined,
  email: undefined,
  configuration: undefined,
  employeeId: undefined,
  name: undefined,
  phone: undefined,
  assets: undefined,
  permission: undefined,
  organization: undefined,
  company: undefined,
  setUserData: action((state, props) => ({
    ...state,
    ...props
  })),
  setConfiguration: action((state, configuration) => {
    const language = configuration.language || 'en' as AvailableLanguages;
    AppLanguage.set(language);
    return ({
      ...state,
      configuration
    });
  })
};

export default model;

interface SetUserDataProps {
  tokenId?: string;
  firebaseId?: string;
  userId?: string;
  email?: string;
  configuration?: Configuration;
  employeeId?: string;
  name?: string;
  phone?: string | null;
  assets?: { images?: { base: string, icon?: string, thumb?: string } };
  permission?: Permission;
  userStatus?: string;
  organization?: Organization;
  company?: Company;
}
