import Dialog from '@material-ui/core/Dialog';
import { Formik } from 'formik';
import _ from 'lodash';

import React from 'react';
import { FormProps } from 'Store/models/formModel';
import styled from 'styled-components';
import * as yup from 'yup';

import { CloseIconButton } from 'Components/atoms/ManyIconButton_portal';
import t from 'Helpers/translations';
import { useStoreActions, useStoreState } from 'Store/index';
import DialogContent from './components/DialogContent/DialogContent';

const GlobalForm = () => {
  const props: FormProps = useStoreState(state => state.form.props);
  const { removeForm } = useStoreActions(actions => actions.form);

  const validationSchema = () => {
    const yupShape: any = {};
    props.fields.forEach((field: any) => {
      if (field.maxLength) {
        yupShape[field.id] = yup.string().nullable().max(field.maxLength, `${t('form_error_of_characters_0')} ${field.maxLength} ${t('form_error_of_characters_1')}`);
      }
      if (field.required && !field.notEditable) {
        yupShape[field.id] = yupShape[field.id] || yup.string().nullable();
        yupShape[field.id] = yupShape[field.id].trim().required(`${field.name} ${t('form_error_required')}`);
      }
      if (field.email) {
        yupShape[field.id] = yupShape[field.id] || yup.string().nullable();
        yupShape[field.id] = yupShape[field.id].trim().email(t(`form_error_invalid_email`));
      }
      if (field.type === 'phone') {
        yupShape[field.id] = yupShape[field.id] || yup.string().nullable();
        yupShape[field.id] = yupShape[field.id].test('phone', t(`form_error_invalid_phone_number`), (value: any) => {
          if (value) {
            const numbers = value.match(/\d/g);
            return numbers && numbers.length === 10; // validation partiel pour un numero de telephone
          }
          return !field.required;
        });
      }
    });
    return yup.object().shape(yupShape);
  };

  const initValues = (_.has(props.defaultValues, 'color')) ? { ...props.defaultValues, color: props.defaultValues.color || randomColor() } : { ...props.defaultValues };

  const handleSubmit = (values: any, { resetForm }: {resetForm: (nextValues?: any) => void}) => {
    const trimedValues = Object.assign({}, values);
    props.fields.forEach((field: any) => {
      const obj = trimedValues[field.id];
      if (typeof obj === 'string') {
        trimedValues[field.id] = obj.trim();
      }
    });
    props.onUpsert(trimedValues);
    removeForm();
    resetForm();
  };
  // const onCancelDate = () => {
  //   setPopupDate(null);
  // };
  // const onCancelColor = () => {
  //   setPopupColor(null);
  // };

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formikProps => {
        const { values,
          setFieldValue,
          submitForm,
          resetForm
        } = formikProps;
        const handleClose = () => {
          removeForm();
          resetForm();
        };

        // const showPopupDate = () => {
        //   if (popupDate) {
        //     return <PopupDate
        //       date={popupDate && moment(values[popupDate.id], 'kk:mm').toDate()}
        //       onCancel={onCancelDate}
        //       onConfirm={onChooseDate}
        //     />;
        //   }
        // };

        // const showPopupColor = () => {
        //   if (popupColor) {
        //     // Not tested yet when list is virtualized to test this part
        //     return <PopupColor
        //       open
        //       color={values[popupColor.id]}
        //       onCancel={onCancelColor}
        //       onConfirm={onChooseColor}
        //     />;
        //   }
        // };
        // const onChooseColor = (newColor: string) => {
        //   if (popupColor) {
        //     setFieldValue(popupColor.id, newColor);
        //   }
        //   setPopupColor(null);
        // };
        // const onChooseDate = (newDate: any) => {
        //   let upsertDateValue;
        //   if (popupDate) {
        //     if (popupDate.id === 'end') {
        //       upsertDateValue = moment(newDate).format('kk:mm');
        //     } else {
        //       upsertDateValue = moment(newDate).format('HH:mm');
        //     }
        //     setFieldValue(popupDate.id, upsertDateValue);
        //     setPopupDate(null);
        //   }

        // };
        const subSelectFields = _.filter(props.fields, field => field.subSelect);

        _.forEach(subSelectFields, subSelectField => {
          const subSelectOptions = _.get(values, [subSelectField.subSelect.field, subSelectField.subSelect.value], []);
          const subSelectFieldValue = values[subSelectField.id];

          if (_.isArray(subSelectFieldValue)) { // multiselect
            const newValue: any = _.intersection(subSelectOptions, subSelectFieldValue);
            if (!_.isEqual(newValue, subSelectFieldValue)) {
              setFieldValue(subSelectField.id, newValue);
            }
          } else { // select
            if (subSelectFieldValue && !_.find(subSelectOptions, subSelectFieldValue)) {
              setFieldValue(subSelectField.id, null);
            }
          }
        });
        return (
          <StyledDialog
            open={props.open}
            onClose={handleClose}
            scroll='body'
            fullScreen
          >
            {/* <PermissionDescription /> */}
            <DialogContainer>
              {/* Header */}
              <DialogHeader>
                <DialogHeaderContent>
                  <Title>
                    {props.title}
                  </Title>
                  <Spacer />
                  <CloseIconButton
                    onClick={handleClose}
                  />
                </DialogHeaderContent>
              </DialogHeader>
              {/* Content */}
              <DialogContent
                {...formikProps}
                fields={props.fields}
                type={props.type}
                firebase={props.firebase}
                // setPopupDateVisible={setPopupDate}
                // setPopupColorVisible={setPopupColor}
              />
              <SectionButtons id='sectionButtons'>
                <Spacer />
                <BigButtonCancel onClick={handleClose}>{t('cancel_button_label')}</BigButtonCancel>
                <BigButtonSubmit onClick={submitForm}>{props.buttonText}</BigButtonSubmit>
              </SectionButtons>
            </DialogContainer>
            {/* {showPopupDate()} */}
            {/* {showPopupColor()} */}
          </StyledDialog>
        );
      }}
    </Formik>
  );
};

const Title = styled.div`
  font-size: 16px;
  font-family: Roboto;
  display: block;
  color: #222d32;
`;

const StyledDialog = styled(Dialog) <any>`
    overflow: visible !important;
    width:100% ;
  `;

const DialogContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #343f4b;
    font-family: Roboto, Regular;
  `;

const DialogHeader = styled.div`
    height: 40px;
    width: 100%;
    background: #fafafa;
    font-size: 15px;
    display: table;
    padding: 0 15px 0 15px;
  `;

const DialogHeaderContent = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
  `;

const Spacer = styled.div`
    flex:1;
  `;

// const StyledRoundButton = styled(RoundButton) <any>`
//   && {
//     width: 20px !important;
//     height: 20px !important;
//     border-radius: 50%;
//     background: #f95f62 !important;
//     color: #ffffff;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     box-shadow: 0px 3px 6px 0px #f95f62;
//     cursor: pointer;
//   }
// && :hover{
//     background: #e0393d;
//   }
// `;

// const StyledCloseIcon = styled(CloseIcon)`
//   && > path {
//         fill: #fff;
//     }
//   `;

const SectionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  margin: 0 30px 26px 30px;
`;

const BigButtonCancel = styled.div`
 height: 32px;
  min-height: 32px;
  border-radius: 16px;
  padding: 0 27px;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
  float: 'left';
  margin-right: '20px';
  color: #f95f62;
  background-color: transparent;
  box-shadow: none;
  &:hover{
    color: #e0393d;
    background-color: transparent;
  }

`;

const BigButtonSubmit = styled.div`
  height: 32px;
  min-height: 32px;
  border-radius: 16px;
  background-color: rgba(0, 166, 255, 1);
  box-shadow: 0 3px 3px rgba(0, 166, 255, 0.38);
  padding: 0 27px;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
  float: 'left';
`;
export default GlobalForm;
