import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from '../../../../store';

import UppyForm from './components/UppyForm';

const GlobalDialog = () => {
  const props = useStoreState(state => state.addFileDialog.props);
  const removeAddFileDialog = useStoreActions(actions => actions.addFileDialog.removeAddFileDialog);
  const handleClose = () => {
    removeAddFileDialog();
  };
  return (
    <StyledDialog
      fullScreen
      open={props.open}
      scroll='body'
      onClose={handleClose}
    >
      <UppyForm onClose={handleClose} />
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)<any>`
  z-index: 9000;
  overflow: visible !important;
  max-width: initial;
`;

export default GlobalDialog;
