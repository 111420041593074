import { DialogProps } from '@material-ui/core/Dialog';
import { Action, action } from 'easy-peasy';

export interface DialogModel {
  props: DialogProps;
  setDialog: Action<DialogModel, Omit<DialogProps, 'open'>>;
  removeDialog: Action<DialogModel, void>;
}

const defaultProps: DialogProps = {
  children: null,
  open: false
};

const model: DialogModel = {
  props: defaultProps,
  setDialog: action((state, props) => ({
    ...state,
    props: {
      ...props,
      open: true
    }
  })),
  removeDialog: action(state => ({
    ...state,
    props: {...state.props,
      open: false}
  }))
};

export default model;
