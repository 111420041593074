import Menu from '@material-ui/core/Menu';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from '../../../../store';

const GlobalMenu = () => {
  const props = useStoreState(state => state.menu.props);
  const { removeMenu } = useStoreActions(actions => actions.menu);
  const closeMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    removeMenu();
    event.stopPropagation();
  };

  return (
    <StyledMenu {...props} onClose={closeMenu} />
  );
};

const StyledMenu = styled(Menu) <any>`
  z-index: 9000;
`;

export default GlobalMenu;
