import moment from 'moment';
import translations from './translations';

export enum AvailableLanguages {
  French = 'fr',
  English = 'en'
}

export enum AvailableLanguagesLabel {
  fr = 'Français',
  en = 'English'
}

export type StringKey = keyof typeof translations;

export const defaultLanguage = AvailableLanguages.English;

const currentLang = {
  lang: defaultLanguage
};

export const AppLanguage = {
  set: (lang: AvailableLanguages) => {
    currentLang.lang = lang;
    moment.locale(lang);
  },
  get: () => currentLang.lang
};

Object.freeze(AppLanguage);

const t = (key: StringKey): string => {
  const strings = translations[key];
  const language = AppLanguage.get();
  return (strings[language] || strings[defaultLanguage]) as string;
};

export default t;
