import React from 'react';

import GlobalAddFileDialog from './GlobalAddFileDialog';
import GlobalDialog from './GlobalDialog';
import GlobalDrawer from './GlobalDrawer';
import GlobalForm from './GlobalForm';
import GlobalMenu from './GlobalMenu';
import GlobalPopper from './GlobalPopper';

const GlobalComponents = () => {
  return(
  <>
    <GlobalAddFileDialog />
    <GlobalDialog />
    <GlobalDrawer />
    <GlobalForm />
    <GlobalMenu />
    <GlobalPopper />
  </>
  );
};

export default GlobalComponents;
