import axios from 'axios';
import { StoreProvider as EasyProvider } from 'easy-peasy';
import firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import Notifications from 'react-notify-toast';
import { BrowserRouter } from 'react-router-dom';

import easyStore from './store';

import Auth from './Auth';
import * as serviceWorker from './serviceWorker';

// Side-effects
import 'firebase/auth';
import 'firebase/storage';

import GlobalComponents from 'Components/organisms/GlobalComponents';
import App from './App';

if (process.env.REACT_APP_FIREBASE_CONFIG) {
  firebase.initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
} else {
  throw new Error('Environment variable REACT_APP_FIREBASE_CONFIG not set!');
}

axios.defaults.baseURL = process.env.REACT_APP_SOMBRA_URL;
axios.defaults.headers.common.Authorization = 'Bearer';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const renderApp = () => (
  <EasyProvider store={easyStore}>
    <BrowserRouter>
      <Auth>
        <App />
        <GlobalComponents />
      </Auth>
      <Notifications options={{ zIndex: 5000 }} />
    </BrowserRouter>
  </EasyProvider>
);

ReactDOM.render(renderApp(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
