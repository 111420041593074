import { MenuProps } from '@material-ui/core/Menu';
import { Action, action } from 'easy-peasy';

export interface MenuModel {
  props: MenuProps;
  setMenu: Action<MenuModel, Omit<MenuProps, 'open'>>;
  removeMenu: Action<MenuModel, void>;
}

const defaultProps: MenuProps = {
  children: null,
  open: false
};

const model: MenuModel = {
  props: defaultProps,
  setMenu: action((state, props) => ({
    ...state,
    props: {
      ...props,
      open: true
    }
  })),
  removeMenu: action(state => ({
    ...state,
    props: {
      ...state.props,
      open: false
    }
  }))
};

export default model;
