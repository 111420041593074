import Popper from '@material-ui/core/Popper';
import React from 'react';
import { useStoreState } from 'Store/index';
import styled from 'styled-components';

const GlobalPopper = () => {
  const props = useStoreState(state => state.popper.props);

  return <StyledPopper {...props} />;
};

const StyledPopper = styled(Popper)<any>`
  z-index: 9000;
`;

export default GlobalPopper;
