type AnimationCurve = [number, number, number, number];
type AnimationFunc = (cssProperty: string, duration: string, delay?: string) => string;
type BaseAnimationFunc = (curve: AnimationCurve, name: string, duration: string, delay?: string) => string;

const DECELERATION_CURVE: AnimationCurve = [0, 0, .2, 1];
const STANDARD_CURVE: AnimationCurve = [.4, 0, .2, 1];
const ACCELERATION_CURVE: AnimationCurve = [.4, 0, 1, 1];
const SHARP_CURVE: AnimationCurve = [.4, 0, .6, 1];
const bezier = ([x1, y1, x2, y2]: AnimationCurve) =>
  `cubic-bezier(${x1}, ${y1}, ${x2}, ${y2})`;

const getAnimation: BaseAnimationFunc = (curve, cssProperty, duration, delay = '0ms') =>
  `${cssProperty} ${duration} ${delay} ${bezier(curve)}`;

const enter: AnimationFunc = (cssProperty, duration, delay) =>
  getAnimation(DECELERATION_CURVE, cssProperty, duration, delay);

const permaExit: AnimationFunc = (cssProperty, duration, delay) =>
  getAnimation(ACCELERATION_CURVE, cssProperty, duration, delay);

const tempExit: AnimationFunc = (cssProperty, duration, delay) =>
  getAnimation(SHARP_CURVE, cssProperty, duration, delay);

const standard: AnimationFunc = (cssProperty, duration, delay) =>
  getAnimation(STANDARD_CURVE, cssProperty, duration, delay);

export {
  ACCELERATION_CURVE,
  DECELERATION_CURVE,
  SHARP_CURVE,
  STANDARD_CURVE,
  bezier,
  enter,
  permaExit,
  standard,
  tempExit
};
