import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from '../../../../store';

const GlobalDialog = () => {
  const props = useStoreState(state => state.dialog.props);
  const removeDialog = useStoreActions(actions => actions.dialog.removeDialog);
  const handleClose = () => {
    removeDialog();
  };
  return <StyledDialog
    {...props}
    open={props.open}
    scroll='body'
    keepMounted
    onClose={handleClose}
  />;
};

const StyledDialog = styled(Dialog) <any>`
  z-index: 9000;
  overflow: visible !important;
  max-width: initial;
`;

export default GlobalDialog;
