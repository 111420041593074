const resourcesManager: Permission = {
  order: 4,
  label: 'Resources Manager',
  rules: {
    dashboard: { view: true, edit: false, viewOwnTeamOnly: false, viewSubPage: true },
    documents: { view: false, create: false, edit: false, delete: false },
    timeline: { view: true, create: false, viewOwnTeamOnly: false, edit: false, delete: false, restore: false, permanentDelete: false },
    dailyLog: {
      view: true,
      create: false,
      edit: false,
      delete: false,
      restore: false,
      permanentDelete: false,
      viewSubPage: true,
      previousStatus: false,
      sendSMS: true,
      documents: { view: true, create: false, edit: true, delete: false }
    },
    priceAnalysis: { view: true, edit: true },
    map: { view: true },
    projectPage: { view: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    projectList: { view: true, viewSameOrganizationOnly: false, create: true, edit: true, delete: false, restore: false, permanentDelete: false, viewSubPage: false },
    projectDescription: { view: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    projectAnalysis: { view: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    report: { view: true, viewSelfOnly: false, create: true, edit: true, delete: true, restore: false, permanentDelete: false },
    organizations: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false, viewSubPage: false },
    organizationPage: { view: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false },
    activities: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false, editActivityPriceType: false },
    materials: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false },
    equipments: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false },
    employees: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false },
    teams: { view: true, create: true, edit: true, delete: true, restore: false, permanentDelete: false },
    administration: { view: false, create: false, edit: false, delete: false, restore: false, permanentDelete: false }
  },
  value: 'resourcesManager'
};

export default resourcesManager;
