import React, { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStoreActions, useStoreState } from './store';

import { useUserDataQuery } from 'apollo/hooks';
import LoadingPage from 'Components/pages/LoadingPage';
import firebase from 'firebase';
import { AppLanguage } from 'Helpers/translations';
import { getPermission } from 'permission';

const DailyLogPage = React.lazy(() => import(/* webpackChunkName: "DailyLogPage" */ 'Components/pages/DailyLogPage'));
const DailyLogEquipmentsPage = React.lazy(() => import(/* webpackChunkName: "DailyLogEquipmentsPage" */ 'Components/pages/DailyLogEquipmentsPage'));
const DailyLogEmployeesPage = React.lazy(/* webpackChunkName: "DailyLogEmployeesPage" */() => import('Components/pages/DailyLogEmployeesPage'));
const DailyLogComments = React.lazy(() => import(/* webpackChunkName: "DailyLogComments" */ 'Components/pages/DailyLogComments'));
const DailyLogActivityPage = React.lazy(() => import(/* webpackChunkName: "DailyLogPage" */ 'Components/pages/DailyLogActivityPage'));
const LoginPage = React.lazy(() => import(/* webpackChunkName: "LoginPage" */ 'Components/pages/LoginPage'));
const TimelinePage = React.lazy(() => import(/* webpackChunkName: "TimelinePage" */ 'Components/pages/TimelinePage'));
const ErrorPage = React.lazy(() => import(/* webpackChunkName: "ErrorPage" */ 'Components/pages/ErrorPage'));
const ForgotPasswordPage = React.lazy(() => import(/* webpackChunkName: "ForgotPasswordPage" */ 'Components/pages/ForgotPasswordPage'));
const DocumentsPage = React.lazy(() => import(/* webpackChunkName: "DocumentsPage" */ 'Components/pages/DocumentsPage'));

export default function App() {
  const setUserData = useStoreActions(actions => actions.user.setUserData);
  const firebaseId = useStoreState(state => state.user.firebaseId);
  const userData = useUserDataQuery({ variables: { firebaseId: firebaseId || '' }, skip: !firebaseId });
  useEffect(() => {
    const firebaseUser = firebase.auth().currentUser;

    if (firebaseUser == null || firebaseUser.email == null) {
      return;
    }

    if (userData.data && !userData.loading) {
      const user = userData.data.users[0];
      const employee = user.employees[0];
      setUserData({
        userId: user.id,
        email: firebaseUser.email,
        configuration: user.configuration,
        employeeId: employee.id,
        name: employee.name,
        phone: employee.phone,
        assets: employee.assets,
        permission: getPermission(employee.permission),
        company: {
          id: employee.company.id,
          organization: {
            id: employee.company.organizations[0].id,
            name: employee.company.organizations[0].name
          }
        },
        organization: {
          id: employee.organization.id,
          name: employee.organization.name
        }
      });
      AppLanguage.set(user.configuration && user.configuration.language || 'en');
    }
  }, [userData.data, userData.loading, setUserData]);

  if (firebaseId) {
    return (
      <Suspense fallback={<LoadingPage fullscreen />}>
        <Switch>
          <Route path='/' exact component={TimelinePage} />
          <Route path='/dailyLog/:id' exact component={DailyLogPage} />
          <Route path='/dailyLog/:id/equipments' exact component={DailyLogEquipmentsPage} />
          <Route path='/dailyLog/:id/documents' exact component={DocumentsPage} />
          <Route path='/dailyLog/:id/activities' exact component={DailyLogActivityPage} />
          <Route path='/dailyLog/:id/employees' exact component={DailyLogEmployeesPage} />
          <Route path='/dailyLog/:id/comments' exact component={DailyLogComments} />
          <Route component={ErrorPage} />
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <Suspense fallback={<LoadingPage fullscreen />}>
        <Switch>
          <Route path='/' exact component={LoginPage} />
          <Route path='/forgotPassword' exact component={ForgotPasswordPage} />
          <Route component={ErrorPage} />
        </Switch>
      </Suspense>
    );
  }
}
