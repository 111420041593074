import React, { useRef, useState } from 'react';
import styled, { css, keyframes, Keyframes } from 'styled-components';

import { bezier, STANDARD_CURVE } from 'Helpers/animation/materialTiming';
import useRect, { RectResult } from 'Helpers/hooks/useRect';

export interface RippleProps {
  style?: React.CSSProperties;
  color?: string;
  lifespan?: string;
  type?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
  borderRadius?: string;
}

type Point = Record<'x' | 'y', number>;

type AnimationStatus = 'neutral' | 'in' | 'active' | 'out';

interface RippleEffect {
  initialSize: number;
  animation: Keyframes;
}

const Ripple: React.FC<RippleProps> = ({ color: rippleColor = '#000', lifespan = '225ms', children, ...props }) => {
  const [rippleProps, setRippleProps] = useState<RippleEffect>({
    initialSize: 0,
    animation: keyframes``
  });
  const [mouseEnabled, setMouseEnabled] = useState(true);
  const [touching, toggleTouching] = useState(false);
  const [animationStatus, setAnimationStatus] = useState<AnimationStatus>('neutral');
  const ref = useRef<HTMLDivElement>(null);
  const rect = useRect(ref);

  const createRipple: React.MouseEventHandler<HTMLDivElement> = ({ clientX, clientY }) => {
    if (!mouseEnabled) {
      return;
    }

    const newRippleEffect = makeRipple(rect, clientX, clientY);

    toggleTouching(true);
    setAnimationStatus('in');
    setRippleProps(newRippleEffect);
  };

  const createTouchRipple: React.TouchEventHandler<HTMLDivElement> = event => {
    const newRippleEffect = makeRipple(rect, event.touches[0].clientX, event.touches[0].clientY);

    setMouseEnabled(false);
    toggleTouching(true);
    setAnimationStatus('in');
    setRippleProps(newRippleEffect);
  };

  const setInactive = () => {
    toggleTouching(false);

    if (animationStatus === 'active') {
      setAnimationStatus('out');
    }
  };

  const onAnimationEnd: React.AnimationEventHandler<HTMLDivElement> = ({ animationName }) => {
    if (animationName === rippleProps.animation.getName()) {
      setAnimationStatus(touching ? 'active' : 'out');
    }

    if (animationName === rippleOpacityOut.getName()) {
      setAnimationStatus('neutral');
    }
  };

  return (
    <RippleContainer
      {...props}
      {...rippleProps}
      lifespan={lifespan}
      rippleColor={rippleColor}
      animationStatus={animationStatus}
      ref={ref}
      onAnimationEnd={onAnimationEnd}
      onMouseDown={createRipple}
      onMouseUp={setInactive}
      onMouseLeave={setInactive}
      onTouchStart={createTouchRipple}
      onTouchEnd={setInactive}
    >
      {children}
    </RippleContainer>
  );
};

const makeRipple = (rect: RectResult, clientX: number, clientY: number) => {
  const { width, height, left, top } = rect;

  const getBoundedRadius = () => {
    const hypotenuse = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
    return hypotenuse + 10;
  };

  const maxDim = Math.max(width, height);
  const maxRadius = getBoundedRadius();
  const initialSize = Math.floor(maxDim * .6);
  const rippleScale = `${maxRadius / initialSize}`;
  const startPosition = {
    x: (clientX + window.scrollX) - left - (initialSize / 2),
    y: (clientY + window.scrollY) - top - (initialSize / 2)
  };

  const endPosition = {
    x: (width / 2) - (initialSize / 2),
    y: (height / 2) - (initialSize / 2)
  };

  const ripple: RippleEffect = {
    initialSize,
    animation: rippleRadiusIn(rippleScale, startPosition, endPosition)
  };

  return ripple;
};

const RippleContainer = styled.div<RippleEffect & { animationStatus: AnimationStatus; rippleColor: string; lifespan: string; borderRadius?: string; }>`
  display: inline-block;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  ${props => props.borderRadius && css`
    border-radius: ${props.borderRadius};
  `}

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => `${props.initialSize}px`};
    height: ${props => `${props.initialSize}px`};
    background: ${props => props.rippleColor};
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    transform: scale(0);
    transform-origin: center center;
    ${props => rippleAnimation(props.animationStatus, props.lifespan, props.animation)}
  }
`;

const rippleAnimation = (animationStatus: AnimationStatus, lifespan: string, animation: Keyframes | null) => {
  switch (animationStatus) {
    case 'in':
    case 'active':
      return css`
        animation:
          ${animation} ${lifespan} forwards,
          ${rippleOpacityIn} 75ms forwards;
      `;
    case 'out':
      return css`
        animation: ${rippleOpacityOut} 150ms;
        transform: scale(100);
      `;
    default:
      return css``;
  }
};

const rippleRadiusIn = (scale: string, startPosition: Point, endPosition: Point) => keyframes`
  from {
    animation-timing-function: ${bezier(STANDARD_CURVE)};
    transform: translate(${startPosition.x}px, ${startPosition.y}px) scale(1);
  }

  to {
    transform: translate(${endPosition.x}px, ${endPosition.y}px) scale(${scale});
  }
`;

const rippleOpacityIn = keyframes`
  from {
    animation-timing-function: linear;
    opacity: 0;
  }

  to {
    opacity: .12;
  }
`;

const rippleOpacityOut = keyframes`
  from {
    animation-timing-function: linear;
    opacity: .12;
  }

  to {
    opacity: 0;
  }
`;

export default Ripple;
