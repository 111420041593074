export default (error: any) => ({
  control: (styles: any, { isFocused, isDisabled }: any) => {
    let controlStyle = {
      ...styles,
      backgroundColor: !error ? '#ffffff' : 'rgba(249, 95, 98, 0.1)',
      border: !error ? '1px solid #e4e8ee' : '1px solid rgba(249, 95, 98, 1)',
      borderRadius: '23px',
      padding: '0 14px',
      fontSize: '14px',
      cursor: 'pointer',
      '&:hover': {}
    };
    if (isDisabled) {
      controlStyle = {
        ...controlStyle,
        backgroundColor: '#eaeaea',
        border: '1px solid #ced0d4'
      };
    } else if (isFocused) {
      controlStyle = {
        ...controlStyle,
        border: !error ? '1px solid #80bdff' : '1px solid rgba(249, 95, 98, 1)',
        boxShadow: !error ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : '0 0 0 0.2rem rgba(249, 95, 98, 0.25)',
        '&:hover': {}
      };
    }
    return controlStyle;
  },
  // menu: styles => ({
  //   ...styles,
  //   position: 'fixed !important',
  //   zIndex: 100
  // }),
  valueContainer: (styles: any) => ({
    ...styles,
    overflow: 'hidden'
  })
});
