import { IconButton } from '@material-ui/core';
import * as React from 'react';
import styled, { css } from 'styled-components';

import CloseIcon from 'mdi-react/CloseIcon';

export const CloseIconButton = (props: {onClick: () => void}) => (
  <StyledIconButton onClick={props.onClick}>
    <StyledCloseIcon />
  </StyledIconButton>
);

const StyledIconButton = styled(IconButton)`
  display: flex;
  align-content: center;
  justify-content: center;

  && {
    padding: 10px;
    width: 50px;
    height: 50px;
    :focus {
      outline: 0;
    }

    :hover {
      background: rgba(0, 0, 0, 0.08);
    }
  }
`;

const SharedIconStyle = css`
  && {
    border: none;
    outline: none;
  }
  width: 18px;
  height: 18px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  ${SharedIconStyle};
`;
