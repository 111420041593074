import styled from 'styled-components';

import { withRipple } from 'Components/atoms/Ripple';

const StyledButton = styled.button`
  display: flex;
  align-content: center;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #A3A6B4;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0px;
  width: 32px;
  height: 32px;
  :focus {
    outline: 0;
  }
`;

export default withRipple(StyledButton);
