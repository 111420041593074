import Uppy, { Plugin } from '@uppy/core';
import uuid from 'uuid/v4';

interface PluginOptions extends Uppy.PluginOptions {
  storageRef: firebase.storage.Reference;
}

export default class FirebaseCloudStorage extends Plugin {
  public title: string;
  public storageRef: firebase.storage.Reference;

  constructor(uppy: Uppy.Uppy, opts: PluginOptions) {
    super(uppy, opts);
    if (!opts.storageRef) {
      throw Error(
        'Please provide the root storageRef to be used as option `storageRef`. See https://firebase.google.com/docs/storage/web/upload-files'
      );
    }
    this.type = 'uploader';
    this.id = 'FirebaseCloudStorage';
    this.title = 'Firebase Cloud Storage';
    this.storageRef = opts.storageRef;
    this.uploadFile = this.uploadFile.bind(this);
  }

  public uploadFile(fileIds: string[]) {
    return Promise.all(
      fileIds.map((id: string) => {
        return new Promise((resolve, reject) => {
          const file = this.uppy.getFile(id);
          const refId = uuid();
          const fileRef = this.storageRef.child(refId);
          const metaData = {
            contentType: file.type
          };
          // @ts-ignore
          this.uppy.emit('upload-started', file);
          const uploadTask = fileRef.put(file.data, metaData);
          uploadTask.on(
            'state_changed',
            snapshot => {
              const progressInfo = {
                uploader: this,
                bytesUploaded: snapshot.bytesTransferred,
                bytesTotal: snapshot.totalBytes
              };
              // @ts-ignore
              this.uppy.emit('upload-progress', file, progressInfo);
            },
            error => {
              // @ts-ignore
              this.uppy.emit('upload-error', file, error);
              reject(error);
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(downloadUrl => {
                const uppyFile = this.uppy.getFile(id);
                // @ts-ignore
                uppyFile.downloadUrl = downloadUrl;
                // @ts-ignore
                this.uppy.emit(
                  'upload-success',
                  uppyFile,
                  uploadTask.snapshot,
                  downloadUrl
                );
                resolve();
              });
            }
          );
        });
      })
    );
  }

  public install() {
    this.uppy.addUploader(this.uploadFile);
  }

  public uninstall() {
    this.uppy.removeUploader(this.uploadFile);
  }
}
