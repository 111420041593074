import axios from 'axios';
import { notify } from 'react-notify-toast';

import t from 'Helpers/translations';

interface Document {
  id?: string;
  name?: string;
  url?: string;
  showInDailyLogs?: boolean;
  metadata?: {
    type: string;
    thumbnail: string;
  };
  companyId?: string;
  projectId?: string;
  dailyLogId?: string;
  employeeId?: string;
}

export const upsertDocument: Upsert<Document> = async props => {
  const { id } = props;
  try {
    const { data } = await axios.post('/document/upsert', props);
    notify.show(t(id ? 'dailylog_document_update_success' : 'dailylog_document_add_success'), 'success');
    return { success: true, data };
  } catch (error) {
    notify.show(t('dailylog_document_upsert_error'), 'error');
    console.error(error);
    return { success: false };
  }
};

interface AddMultipleDocumentsBody {
  documents: {
    name: string;
    url: string;
    showInDailyLogs: boolean;
    metadata: {
      type?: string;
      thumbnail?: string;
    };
  }[];
  companyId: string;
  projectId: string | null;
  dailyLogId: string | null;
  employeeId: string;
}

export async function addMultipleDocuments(props: AddMultipleDocumentsBody) {
  try {
    const { data } = await axios.post('/document/addMultipleDocuments', props);
    notify.show(t('dailylog_documents_add_success'), 'success');
    return { success: true, data };
  } catch (error) {
    notify.show(t('dailylog_documents_add_error'), 'error');
    console.error(error);
    return { success: false };
  }
}

export async function deleteDocument(id: string) {
  try {
    const { data } = await axios.delete(`/document/delete/${id}`);
    notify.show(t('dailylog_document_delete_success'), 'success');
    return { success: true, data };
  } catch (error) {
    notify.show(t('dailylog_document_delete_error'), 'error');
    console.error(error);
    return { success: false };
  }
}
