import React, { Component } from 'react';
import styled from 'styled-components';

import Ripple, { RippleProps } from './Ripple';

interface HOCProps {
  rippleProps?: RippleProps;
}

export default function withRipple<T>(PassedComponent: React.FC<T>): React.FC<HOCProps & T> {
  return ({ rippleProps, ...props }) =>  {
    return (
      <Ripple {...rippleProps}>
        <PassedComponent {...props as T} />
      </Ripple>
    );
  };
}
